// Theme White

// Variables

$app-container-bg: rgba(251, 251, 251, 0);
$app-sidebar-bg: rgba(0, 0, 0, 0.74);
$app-header-bg: transparent;
$app-header-logo-bg: rgba(255, 255, 255, 0.883);

// Content

.app-theme-white {
  &.app-container {
    background: $app-container-bg;
    background-color: 77ee7e;
  }
  a {
    color: whitesmoke;
  }

  .app-sidebar-bg {
    height: 100%;
    padding: 0;
    color: whitesmoke;
  }
  .app-sidebar {
    background: $app-sidebar-bg;
    height: 120%;
    color: whitesmoke;
  }
  .app-sidebar-bg {
    height: 120%;
  }
  .app-page-title {
    background: rgba(0, 0, 0, 0.24);
    height: 10px;
  }

  .app-footer .app-footer__inner,
  .app-header {
    background: $app-header-bg;
  }

  &.fixed-header {
    .app-header__logo {
      background: 77ee7e;
    }
  }
}
