// Variables

$spacer-lg: 1rem;
$spacer-sm: 1.5rem;

$layout-spacer-lg: 1rem;
$layout-spacer-x: 1.5rem;

$app-sidebar-width: 200px;
$app-sidebar-width-collapsed: 75px;

$logo-height: 46px;
$logo-width: 20px;

$app-header-height: 101px;
