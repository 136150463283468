// Sidebar Animation

.SidebarAnimation-appear {
  transform: translateX(-30px);
  opacity: 0;

  &.SidebarAnimation-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.4s linear;
  }
}

// Tabs Animation

.TabsAnimation-appear {
  @extend .animated;
  @extend .fadeInUp;
}

// Header Animation

.HeaderAnimation-appear {
  transform: translateY(-30px);
  opacity: 0;

  &.HeaderAnimation-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.7s linear;
  }
}

// Main Animation

.MainAnimation-appear {
  transform: translateY(-30px);
  opacity: 0;

  &.MainAnimation-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.7s linear;
  }
} // Main Animation2

.MainAnimation2-appear {
  transform: translateX(30px);
  opacity: 0;

  &.MainAnimation2-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.8s linear;
  }
}
// Main Animation3

.MainAnimation3-appear {
  transform: translateY(-30px);
  opacity: 0;

  &.MainAnimation3-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.9s linear;
  }
}

.MainAnimation4-appear {
  transform: translateY(-30px);
  opacity: 0;

  &.MainAnimation4-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 1s linear;
  }
}
.MainAnimation5-appear {
  transform: translateX(50px);
  opacity: 0;

  &.MainAnimation5-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 1.1s linear;
  }
}
