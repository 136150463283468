/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "Cinzel-Regular";
  src: url("./fonts/Cinzel-Regular.eot"); /* IE9 Compat Modes */
  src: url("./fonts/Cinzel-Regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/Cinzel-Regular.otf") format("opentype"),
    /* Open Type Font */ url("./fonts/Cinzel-Regular.svg") format("svg"),
    /* Legacy iOS */ url("./fonts/Cinzel-Regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Cinzel-Regular.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/Cinzel-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
